import { Messenger } from 'lib/messenger/Messenger.ts';
import { loginOpen, user } from 'signals/login';

export function MessengerOpener({ offerId }: { offerId?: string }) {
	const isMessengerParam = Messenger.hasUrlMessengerParam(window.location.href);
	const isUserInitialized = user.value.state !== 'uninitialized';
	const isUserLoggedIn = user.value.state === 'login';

	if (isUserInitialized && isMessengerParam) {
		if (isUserLoggedIn) {
			Messenger.tryOpenOnPageLoad(offerId);
		} else {
			loginOpen();
		}
	}
}
